import React from 'react';

import './index.scss'
const Timeline = () => {
    return (
        <div className="Timeline">
            <div className="Title"><h4>2022</h4></div>
            <div className="Title"><h4>May</h4></div>
            <div data-num="2" className="Activity">
                <h3>Lustrum Cantus</h3>
                <p>Come to a lustrum classic: the beer cantus! At this event, the band 'De broer van Henk' will perform for us. They will play delicious sing-alongs for us. Besides that you will enjoy other nice acts while enjoying some beer!</p>
                <a href="https://ch.tudelft.nl/events/69838520-6f00-4e0f-88bb-8c1637e0082a" target="_blank" rel="noreferrer" className="Button">Tickets</a>
            </div>
            <div data-num="25" className="Activity">
                <h3>Lusturm Ending Party</h3>
                <p>To be announced.</p>
            </div>
            <div className="Line Line--bl Line--pink Ball--br Ball--pink"></div>
        </div>
    );
};

export default Timeline;